import React from 'react';
import { R } from '@dev2t/react-ui-kit/src/components';
import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { env } from '@config';

const useStyles = makeStyles(theme => ({
    link: {
		color: theme.palette.primary.main,
		textDecoration: 'none',
	},
}));

export default function Disclosures() {
	const classes = useStyles();
	return (
		<Box>
			<Typography variant='h5'>
				<R id='common_menu_disclosures' defaultValue='Disclosures'></R>
			</Typography>
			<a className={classes.link} 
				href={`${env.backendUrl}files/j2tx/2025.01.14%20-%20J2TX%20-%20Regulations%20for%20the%20Provision%20of%20Services.pdf`} 
				target="_blank">
				<R id="j2tx_disclosures_regulations_for_the_provision_of_services" defaultValue="Regulations for the Provision of Services"/>
			</a>
		</Box>
	);
}
